/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import PaymentModal from './PaymentModal';
import ProgressBar from "../../ProgressBar";
import Loader from "../../../common/Loader";
import { createReservationThunk } from '../../../store/slices/reservationSlice';

const ReservePayment = () => {
  const navigate = useNavigate();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const dispatch = useDispatch();
  
  // Get data from Redux store
  const { builder, loading: builderLoading } = useSelector((state) => state.builder);
  const builderConfig = builder?.builder?.builderConfig;
  const { property, loading: propertyLoading } = useSelector((state) => state.property);
  const { userData } = useSelector((state) => state.user);
  const { propertySlug } = useParams();

  const handlePaymentSuccess = async (paymentResult) => {
    try {
      // Create reservation record
      const reservationData = {
        propertyId: property.propertyid,
        userId: userData.userid,
        paymentId: paymentResult.id,
      };

      // Store reservation data in Redux
      await dispatch(createReservationThunk(reservationData)).unwrap();

      // Navigate to complete page
      navigate(`/${propertySlug}/reserve/complete`);
    } catch (error) {
      console.error('Error creating reservation:', error);
      // Handle error appropriately
    }
  };

  const handlePrevious = () => {
    navigate(-1);
  };

  const handleReserveClick = () => {
    setShowPaymentModal(true);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  // Create button style object
  const buttonStyle = {
    backgroundColor: builderConfig?.color || '#default-color',
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && showPaymentModal) {
        closePaymentModal();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    // Cleanup listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [showPaymentModal]); // Only re-run effect if showPaymentModal changes

  return builderLoading || propertyLoading ? (
    <Loader />
  ) : (
    <div className="min-h-screen flex flex-col">

      <div className="lg:hidden flex flex-col w-full gap-15 bg-[#F5F4F0] min-h-screen p-6">
        <div className="h-1/3 w-full flex flex-col gap-10">
          <div className="w-full flex flex-col items-center justify-center">
            <img src="/assets/images/traton_logo.svg" alt="brandlogo" className="h-auto w-1/3 md:w-1/7" />
            <p className="text-small-larken text-body mt-2">
             Powered by Homesy.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-5/7 flex flex-col items-center justify-center gap-10">
          <ProgressBar currentStep={6} totalSteps={6} />
          <div className="flex flex-col items-center justify-center text-center">
            <h1 className="heading-secondary">
              Perfect. Now let's get your home reserved.
              </h1>
              <p className="heading-quinary">
                It's completely refundable during your 48 hours holding period while you schedule a viewing or continue to think it over.
              </p>
            </div>
            <button
                type="button"
                onClick={handleReserveClick}
                style={buttonStyle}
                className="button-text w-full"
              >
              Reserve home for $100
            </button>
          </div>
      </div>

      <div className="hidden lg:flex flex-row w-full min-h-screen">
        {/* Left Side */}
        <div className="w-full w-3/7 flex flex-col bg-[#F5F4F0] items-center justify-center">
          <div className="w-full flex flex-col gap-8 items-center justify-center h-full">
            <div className="flex flex-col items-center w-full">
              {/* <div className="w-5/7 xl:w-4/7 mx-auto">
                <p className="text-small">
                  Holding your home for {formatTime(timeLeft)} ...
                </p>
              </div> */}
            </div>
            <div className="w-5/7 xl:w-4/7 relative mx-auto">
              {/* Blurred background image */}
              <img 
                src={property?.image_urls?.length > 0 
                  ? property?.image_urls[0] 
                  : "/assets/images/placeholder_house.jpg"}
                alt="background blur"
                className="absolute inset-0 top-1.5 h-full w-full scale-95 object-cover blur-[35px] rounded-3xl"
              />
              {/* Main image */}
              <img
                src={property?.image_urls?.length > 0
                  ? property?.image_urls[0]
                  : "/assets/images/placeholder_house.jpg"}
                alt={property?.propertyid}
                className="w-full aspect-square rounded-3xl object-cover relative"
              />
            </div>
            <div className="flex flex-row items-start w-full w-5/7 xl:w-4/7 mx-auto">
              <div className="w-1/2 flex flex-col gap-2">
                <p className="text-custom">${new Intl.NumberFormat('en-US').format(property?.price)}</p>
                <p className="text-small">
                  {property?.property_full_address || "address not found"}
                </p>
                <a
                  className="text-link"
                  href={property?.property_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: builderConfig?.color }}
                >
                  See details
                </a>
              </div>
              <div className="w-1/2 text-custom flex flex-row items-end justify-end gap-4">
                <div className="flex flex-col items-end gap-2">
                  <span className="font-bold">{property?.bedrooms}</span>
                  <span className="text-small">Beds</span>
                </div>
                <div className="flex flex-col items-end gap-2">
                  <span className="font-bold">{property?.bathrooms}</span>
                  <span className="text-small">Baths</span>
                </div>
                <div className="flex flex-col items-end gap-2">
                  <span className="font-bold">{property?.square_feet}</span>
                  <span className="text-small">SqFt</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-full w-4/7 flex flex-col items-center">
          <div className="h-2/7 flex flex-col items-center text-center justify-center">
            <img
              src="/assets/images/traton_logo.svg"
              alt="brandlogo"
              className="h-1/2"
            />
            <p className="text-small-larken text-body mt-2">
              Powered by Homesy.
            </p>
          </div>

          {/* Content Section */}
          <ProgressBar currentStep={7} totalSteps={7} />
          <div className="w-full w-5/7 flex flex-col items-center gap-10">
            <div className="flex flex-col items-center justify-center text-center">
              <h1 className="heading-secondary">
                Perfect. Now let's get your home reserved.
              </h1>
              <p className="heading-quinary">
                It's completely refundable during your 48 hours holding period while you schedule a viewing or continue to think it over.
              </p>
            </div>
            <button
                type="button"
                onClick={handleReserveClick}
                style={buttonStyle}
                className="button-text"
              >
              Reserve home for $100
            </button>
          </div>
        </div>
      </div>

      {/* Payment Modal */}
      {showPaymentModal && (
        <PaymentModal
          builderConfig={builderConfig}
          closePaymentMethod={closePaymentModal}
          propertyData={property?.property}
          userInfo={userData}
          builderInfo={builder?.builder}
          onPaymentSuccess={handlePaymentSuccess}
        />
      )}
    </div>
  );
};

export default ReservePayment;
