import React, { useState } from 'react';
import ProgressBar from '../../../ProgressBar';

const OnsiteAgentStep = ({ userInfo, onChange, errors, skipHandler, onNext, onKeyDown }) => {
  const [selected, setSelected] = useState(null);
  
  const handleSelect = (value) => {
    setSelected(value);
    if (errors.buyerAgent) {
      onChange('errors', {
        ...errors,
        buyerAgent: ''
      });
    }
  };

  const handleSubmit = () => {
    if (!selected) {
      onChange('errors', {
        ...errors,
        buyerAgent: 'Please select whether you are represented by a Buyers agent'
      });
      return false;
    }
    onNext();
    return true;
  };

  return (
    <>
      <ProgressBar currentStep={2} totalSteps={7} />
      <div className="mb-6 flex flex-col items-center gap-10">
        <h1 className="heading-secondary xl:heading-secondary">
          Are you represented by a Buyers agent?
        </h1>
      </div>

      <div className="w-full flex flex-col gap-4">
        <button 
          className={`button-text-secondary ${selected === 'yes' ? 'border-2 border-black' : ''}`}
          onClick={() => {
            handleSelect('yes');
            handleSubmit();
          }}
        >
          Yes
        </button>
        <button 
          className={`button-text-secondary ${selected === 'no' ? 'border-2 border-black' : ''}`}
          onClick={() => {
            handleSelect('no');
            handleSubmit();
          }}
        >
          No
        </button>
        {errors.buyerAgent && (
          <p className="text-red-500 text-sm text-left mt-4">
            {errors.buyerAgent}
          </p>
        )}
      </div>
    </>
  );
};

export default OnsiteAgentStep;