/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../common/Loader";
import { fetchBuilderByIdThunk } from '../../../store/slices/builderSlice';
import { fetchPropertyByIdThunk } from '../../../store/slices/propertySlice';
import formatPropertyId from "../../common/PropertyIdConverter";
import { createOrVerifyUserThunk } from '../../../store/slices/userSlice';

function ReserveGettingStarted() {
  const { propertySlug } = useParams();
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading: userLoading } = useSelector((state) => state.user);

  // Get data from Redux store
  const { builder, loading: builderLoading } = useSelector((state) => state.builder);
  const builderConfig = builder?.builder.builderConfig;
  const { property, loading: propertyLoading } = useSelector((state) => state.property);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const propertyId = formatPropertyId(propertySlug);
        // First fetch property
        const propertyResult = await dispatch(fetchPropertyByIdThunk(propertyId)).unwrap();
        
        // Then fetch builder if we have a builderId
        if (propertyResult?.builderid) {
          await dispatch(fetchBuilderByIdThunk(propertyResult.builderid)).unwrap();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch, propertySlug]);

  const handleGetStarted = async () => {
    if (!isAuthenticated) {
      const returnUrl = `/${propertySlug}/reserve/user-info`;
      
      // Store the dynamic segment (propertySlug) and return path separately
      sessionStorage.setItem('propertySlug', propertySlug);
      sessionStorage.setItem('returnPath', '/reserve/user-info');
      
      try {
        await loginWithRedirect({
          appState: { 
            returnTo: returnUrl,
            propertySlug: propertySlug 
          },
          authorizationParams: {
            redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
            response_type: 'code',
            prompt: 'login'
          }
        });
      } catch (error) {
        console.error('Login redirect error:', error);
      }
    } else {
      try {
        const token = "homesyToken";
        const userData = {
          userid: user.sub,
          email: user.email,
          firstName: user.given_name || '',
          lastName: user.family_name || ''
        };

        const resultAction = await dispatch(createOrVerifyUserThunk({ userData, token }));
        
        if (createOrVerifyUserThunk.fulfilled.match(resultAction)) {
          navigate(`/${propertySlug}/reserve/user-info`);
        } else {
          console.error('Failed to create/verify user:', resultAction.error);
        }
      } catch (error) {
        console.error('Error in handleGetStarted:', error);
      }
    }
  };

  if (builderLoading || propertyLoading || userLoading) {
    return <Loader />;
  }

  // Create button style object
  const buttonStyle = {
    backgroundColor: builderConfig?.color || '#default-color',
  };
  
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      {/* Mobile */}
      <div className="lg:hidden flex flex-col w-full gap-15 bg-[#F5F4F0] min-h-screen p-6 gap-4">
        <div className="h-1/3 w-full flex flex-col gap-10">
          <div className="w-full flex flex-col items-center justify-center">
            <img src="/assets/images/traton_logo.svg" alt="brandlogo" className="h-auto w-1/3 md:w-1/7" />
            <p className="text-small-larken text-body mt-2">
             Powered by Homesy.
            </p>
          </div>
          <div className="w-7/12 md:w-2/7 relative mx-auto">
            {/* Blurred background image */}
            <img 
              src={property?.image_urls?.length > 0 
                ? property?.image_urls[0] 
                : "/assets/images/placeholder_house.jpg"}
              alt="background blur"
              className="absolute inset-0 top-1.5 h-full w-full scale-95 object-cover blur-[35px] rounded-3xl"
            />
            {/* Main image */}
            <img
              src={
                property?.image_urls?.length > 0
                  ? property?.image_urls[0]
                  : "/assets/images/placeholder_house.jpg"
              }
              alt={property?.propertyid}
              className="w-full aspect-square rounded-3xl object-cover relative"
            />
          </div>
        </div>
        <div className="h-2/3 flex flex-col px-4 w-full">
           <div className="flex flex-col text-center items-center justify-center gap-5">
              <div className="flex flex-col items-center justify-center">
                <h1 className="heading-primary md:heading-secondary">
                    Let's reserve your home.
                  </h1>
                  <p className="heading-quinary p-4 md:px-20">
                    Reserve this home for just $100 and hold it for 48 hours—no contract required! Schedule a tour and explore the home at your convenience. Fully refundable if canceled within 48 hours.
                  </p>
              </div>
                <button
                  style={buttonStyle}
                  className="button-text w-full mx-20"
                  onClick={handleGetStarted}
                >
                  Get started
                </button>
           </div>
          </div>
      </div>
      
      {/* Desktop */  }
      <div className="hidden lg:flex flex-row w-full min-h-screen">
        {/* Left Side */}
        <div className="w-full w-3/7 flex flex-col bg-[#F5F4F0] items-center justify-center">
          <div className="w-full flex flex-col gap-8 items-center justify-center h-full">
              <div className="flex flex-col items-center w-full">
                <div className="w-5/7 xl:w-4/7 mx-auto">
                  <p className="text-small">
                    Click <a href="#" onClick={handleGetStarted} style={{color: builderConfig?.color}}>get started</a> to reserve your home.
                  </p>
                </div>
              </div>
                <div className="w-5/7 xl:w-4/7 relative mx-auto">
                  {/* Blurred background image */}
                  <img 
                    src={property?.image_urls?.length > 0 
                      ? property?.image_urls[0] 
                      : "/assets/images/placeholder_house.jpg"}
                    alt="background blur"
                    className="absolute inset-0 top-1.5 h-full w-full scale-95 object-cover blur-[35px] rounded-3xl"
                  />
                  {/* Main image */}
                  <img
                    src={
                      property?.image_urls?.length > 0
                        ? property?.image_urls[0]
                        : "/assets/images/placeholder_house.jpg"
                    }
                    alt={property?.propertyid}
                    className="w-full aspect-square rounded-3xl object-cover relative"
                  />
                </div>
                <div className="flex flex-row items-start w-full w-5/7 xl:w-4/7 mx-auto">
                  <div className="w-1/2 flex flex-col gap-2">
                  <p className="text-custom">${new Intl.NumberFormat('en-US').format(property?.price)}</p>
                    <p className="text-small">
                      {property?.property_full_address || "address not found"}
                    </p>
                    <a
                      className="text-link"
                      href={property?.property_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: builderConfig?.color }}
                    >
                      See details
                    </a>
                  </div>
                  <div className="w-1/2 text-custom flex flex-row items-end justify-end gap-4">
                    <div className="flex flex-col items-end gap-2">
                      <span className="font-bold">{property?.bedrooms}</span>
                      <span className="text-small">Beds</span>
                    </div>
                    <div className="flex flex-col items-end gap-2">
                      <span className="font-bold">{property?.bathrooms}</span>
                      <span className="text-small">Baths</span>
                    </div>
                    <div className="flex flex-col items-end gap-2">
                      <span className="font-bold">{property?.square_feet}</span>
                      <span className="text-small">SqFt</span>
                    </div>
                  </div>
                </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-full w-4/7 flex flex-col items-center gap-10">
          <div className="h-2/7 flex flex-col items-center text-center justify-center">
            <img
              src="/assets/images/traton_logo.svg"
              alt="brandlogo"
              className="mt-10"
            />
            <p className="text-small-larken text-body mt-2">
             Powered by Homesy.
            </p>
          </div>
          
          <div className="h-5/7 flex flex-col px-4 w-5/7">
           <div className="flex flex-col text-center gap-10">
            <h1 className="heading-secondary">
                Let's reserve your home.
              </h1>
              <p className="heading-quinary">
                Reserve this home for just $100 and hold it for 48 hours—no contract required! Schedule a tour and explore the home at your convenience. Fully refundable if canceled within 48 hours.
              </p>
           </div>
           <div className="mt-16 flex justify-end">
            <button
              style={buttonStyle}
              className="button-text"
              onClick={handleGetStarted}
            >
              Get started
            </button>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReserveGettingStarted;