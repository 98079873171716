import React from "react";
import { useSelector } from "react-redux";

function ReserveReserved() {
  const { builder } = useSelector((state) => state.builder);
  const builderConfig = builder?.builder?.builderConfig;
  const { property } = useSelector((state) => state.property);

  const buttonStyle = {
    backgroundColor: builderConfig?.color || '#default-color',
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Mobile */}
      <div className="lg:hidden flex flex-col w-full gap-15 bg-[#F5F4F0] min-h-screen p-6">
        <div className="h-1/3 w-full flex flex-col gap-10">
          <div className="w-full flex flex-col items-center justify-center">
            <img src="/assets/images/traton_logo.svg" alt="brandlogo" className="h-auto w-1/3 md:w-1/7" />
            <p className="text-small text-body mt-2">
             Powered by <a href="https://yourhomesy.com" target="_blank" rel="noopener noreferrer"><span className="text-[#D66C42]">Homesy.</span></a>
            </p>
          </div>
          <div className="w-7/12 md:w-2/7 relative mx-auto">
            {/* Blurred background image */}
            <img 
              src={property?.image_urls?.length > 0 
                ? property?.image_urls[0] 
                : "/assets/images/placeholder_house.jpg"}
              alt="background blur"
              className="absolute inset-0 top-1.5 h-full w-full scale-95 object-cover blur-[35px] rounded-3xl"
            />
            {/* Main image */}
            <img
              src={property?.image_urls?.length > 0
                ? property?.image_urls[0]
                : "/assets/images/placeholder_house.jpg"}
              alt={property?.propertyid}
              className="w-full aspect-square rounded-3xl object-cover relative"
            />
          </div>
        </div>
        <div className="h-2/3 flex flex-col px-4 w-full">
          <div className="flex flex-col text-center items-center justify-center gap-5">
            <h1 className="heading-primary md:heading-secondary">
              Property Currently Reserved
            </h1>
            <p className="heading-quinary p-4 md:px-20">
              Looks like the property is currently reserved by another interested buyer.
            </p>
            <a
              href={property?.property_url}
              style={buttonStyle}
              className="button-text w-full mx-20 text-center text-white"
            >
              Navigate back to website
            </a>
          </div>
        </div>
      </div>

      {/* Desktop */}
      <div className="hidden lg:flex flex-row w-full min-h-screen">
        {/* Left Side */}
        <div className="w-full w-3/7 flex flex-col bg-[#F5F4F0] items-center justify-center">
          <div className="w-full flex flex-col gap-8 items-center justify-center h-full">
            <div className="w-5/7 xl:w-4/7 relative mx-auto">
              {/* Blurred background image */}
              <img 
                src={property?.image_urls?.length > 0 
                  ? property?.image_urls[0] 
                  : "/assets/images/placeholder_house.jpg"}
                alt="background blur"
                className="absolute inset-0 top-1.5 h-full w-full scale-95 object-cover blur-[35px] rounded-3xl"
              />
              {/* Main image */}
              <img
                src={property?.image_urls?.length > 0
                  ? property?.image_urls[0]
                  : "/assets/images/placeholder_house.jpg"}
                alt={property?.propertyid}
                className="w-full aspect-square rounded-3xl object-cover relative"
              />
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-full w-4/7 flex flex-col items-center">
          <div className="h-2/7 flex flex-col items-center text-center justify-center">
            <img
              src="/assets/images/traton_logo.svg"
              alt="brandlogo"
              className="h-1/2"
            />
            <p className="text-small-larken text-body mt-2">
             Powered by Homesy.
            </p>
          </div>
          
          <div className="h-5/7 flex flex-col px-4 w-5/7">
            <div className="flex flex-col text-center gap-10">
              <h1 className="heading-secondary">
                Property Currently Reserved
              </h1>
              <p className="heading-quinary">
                Looks like the property is currently reserved by another interested buyer.
              </p>
            </div>
            <div className="mt-16 flex justify-center">
              <a
                href={property?.property_url}
                style={buttonStyle}
                className="button-text text-white"
              >
                Navigate back to website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReserveReserved;
