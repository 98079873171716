import React from 'react';
import ProgressBar from '../../../ProgressBar';

const AgentStep = ({ userInfo, onChange, errors, skipHandler, onNext, onKeyDown }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
    if (errors.agentName) {
      onChange('errors', {
        ...errors,
        agentName: ''
      });
    }
  };

  const handleSubmit = () => {
    if (!userInfo.agentName || userInfo.agentName.trim() === '') {
      onChange('errors', {
        ...errors,
        agentName: 'Please enter an agent name or click "Skip agent" if you found this house on your own'
      });
      return false;
    }
    onNext();
    return true;
  };

  return (
    <>
      <ProgressBar currentStep={1} totalSteps={6} />
      <div className="mb-6 flex flex-col items-center gap-10">
        <h1 className="heading-secondary xl:heading-secondary">
          First, did a Traton sales agent help you?
        </h1>
        <p className="heading-quinary xl:heading-tertiary">
          Find this house on your own? <span className="text-[#6C1513] cursor-pointer underline" onClick={skipHandler}>Skip agent.</span>
        </p>
      </div>

      <div className="w-full">
        <input
          type="text"
          placeholder="Sales Agent Name"
          name="agentName"
          value={userInfo.agentName || ''}
          onChange={handleChange}
          onBlur={handleSubmit}
          onKeyDown={onKeyDown}
          className="w-full px-4 py-3 text-base border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
        />
        {errors.agentName && (
          <p className="text-red-500 text-sm text-left mt-4">
            {errors.agentName}
          </p>
        )}
      </div>
    </>
  );
};

export default AgentStep; 