import React from 'react';
import ProgressBar from '../../../ProgressBar';

const EmailStep = ({ userInfo, onChange, errors, onNext, onKeyDown }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
    if (errors.email) {
      onChange('errors', {
        ...errors,
        email: ''
      });
    }
  };

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userInfo.email?.trim() || !emailRegex.test(userInfo.email)) {
      onChange('errors', {
        ...errors,
        email: 'Please enter a valid email address'
      });
      return false;
    }
    onNext();
    return true;
  };

  return (
    <>
      <ProgressBar currentStep={4} totalSteps={7} />
      <div className="mb-6 flex flex-col items-center gap-10">
        <h1 className="heading-secondary xl:heading-secondary">
          Best email to send stuff?
        </h1>
        <p className="heading-quinary xl:heading-tertiary">
          This is where we'll send a copy of your contract
        </p>
      </div>
      <div className="w-full">
        <input
          type="text"
          placeholder="Email address"
          name="email"
          value={userInfo.email || ''}
          onChange={handleChange}
          className="w-full px-4 py-3 text-base border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
          onBlur={handleSubmit}
          onKeyDown={onKeyDown}
        />
        {errors.email && (
          <p className="text-red-500 text-sm text-left mt-4">
            {errors.email}
          </p>
        )}
      </div>
    </>
  );
};

export default EmailStep; 