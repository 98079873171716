import React from 'react';
import ProgressBar from '../../../ProgressBar';
import usePhoneInput from '../hooks/usePhoneInput';

const PhoneStep = ({ userInfo, onChange, errors, onNext, onKeyDown }) => {
  const [phoneNumber, handlePhoneChange] = usePhoneInput(userInfo.phone || '', (value) => {
    onChange('phone', value);
    if (errors.phone) {
      onChange('errors', {
        ...errors,
        phone: null
      });
    }
  });

  const handleSubmit = () => {
    const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
    if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
      onChange('errors', {
        ...errors,
        phone: 'Please enter a valid 10-digit phone number'
      });
      return false;
    }
    onNext();
    return true;
  };

  return (
    <>
      <ProgressBar currentStep={5} totalSteps={7} />
      <div className="mb-6 flex flex-col items-center gap-10">
        <h1 className="heading-secondary xl:heading-secondary">
          Good phone number?
        </h1>
        <p className="heading-quinary xl:heading-tertiary">
          We won't call unless we have to.
        </p>
      </div>
      <div className="w-full">
        <input
          type="text"
          placeholder="(XXX) XXX - XXXX"
          name="phone"
          value={phoneNumber}
          onChange={handlePhoneChange}
          maxLength={14}
          className="w-full px-4 py-3 text-base border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
          onBlur={handleSubmit}
          onKeyDown={onKeyDown}
        />
        {errors.phone && (
          <p className="text-red-500 text-sm text-left mt-4">
            {errors.phone}
          </p>
        )}
      </div>
    </>
  );
};

export default PhoneStep; 