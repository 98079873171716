import React from 'react';

const PreviousButton = ({ 
  step, 
  onPrevious, 
  buttonStyle,
  isLoading 
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPrevious();
  };

  // Don't show the button if we're on step 0 (initial screen)
  if (step === 0) return null;

  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={isLoading}
      className="button-text-secondary"
    >
      Back
    </button>
  );
};

export default PreviousButton;
