import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useAuth0} from '@auth0/auth0-react';
import Confetti from 'react-confetti';
import { fetchUserReservationThunk, setReservationByPropertySlug } from '../../../store/slices/reservationSlice';
import { fetchPropertyByIdThunk } from '../../../store/slices/propertySlice';
import Loader from '../../../common/Loader';
import formatPropertyId from '../../../utils/PropertyIdConverter';
import { useNavigate } from 'react-router-dom';

// Create memoized selectors outside the component
const selectReservationState = createSelector(
  [(state) => state.reservations],
  (reservations) => ({
    currentReservation: reservations.currentReservation,
    loading: reservations.loading
  })
);

const selectPropertyState = createSelector(
  [(state) => state.property],
  (property) => ({
    property: property.property,
    loading: property.loading
  })
);

const ReserveComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertySlug } = useParams();
  const { user: auth0User, loginWithRedirect } = useAuth0();
  
  // Use memoized selectors
  const { currentReservation, loading: reservationLoading } = useSelector(selectReservationState);
  const { property, loading: propertyLoading } = useSelector(selectPropertyState);
  const userId = auth0User?.sub;

  const [showConfetti, setShowConfetti] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
      // Start fading out after 4.5 seconds
      const fadeTimer = setTimeout(() => setFadeOut(true), 4500);

      // Stop the confetti after 5 seconds
      const stopTimer = setTimeout(() => setShowConfetti(false), 5000);

      // Cleanup timers
      return () => {
          clearTimeout(fadeTimer);
          clearTimeout(stopTimer);
      };
  }, []);


  useEffect(() => {

    const fetchData = async () => { 

      if (!userId) {
        const returnUrl = `/${propertySlug}/reserve/complete`;
        
        // Store the dynamic segment (propertySlug) and return path separately
        sessionStorage.setItem('propertySlug', propertySlug);
        sessionStorage.setItem('returnPath', '/reserve/complete');
        
        try {
          await loginWithRedirect({
            appState: { 
              returnTo: returnUrl,
              propertySlug: propertySlug 
            },
            authorizationParams: {
              redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
              response_type: 'code',
              prompt: 'login'
            }
          });
          return;
        } catch (error) {
          console.error('Login redirect error:', error);
        }
      }
      if (userId) {
        const propertyId = formatPropertyId(propertySlug);

        try {
          const token = "homesyToken";
          await dispatch(fetchUserReservationThunk({ userId, token })).unwrap();
          dispatch(setReservationByPropertySlug(propertyId));
          
          if (!property?.property) {
            console.log("Fetching property")
            await dispatch(fetchPropertyByIdThunk(propertyId)).unwrap();
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        console.log('No userId available yet');
      }
    };

    fetchData();
  }, [dispatch, auth0User, propertySlug, userId, loginWithRedirect]);
  
  console.log(currentReservation)
  if (reservationLoading || propertyLoading) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
        {showConfetti && (
                <div
                    style={{
                        transition: 'opacity 0.5s ease-in-out',
                        opacity: fadeOut ? 0 : 1,
                    }}
                >
                    <Confetti />
                </div>
            )}
      
      <div className="lg:hidden flex flex-col w-full gap-15 bg-[#F5F4F0] min-h-screen p-6 gap-10">
        <div className="h-1/3 w-full flex flex-col gap-10">
          <div className="w-full flex flex-col items-center justify-center">
            <img src="/assets/images/traton_logo.svg" alt="brandlogo" className="h-auto w-1/3 md:w-1/7" />
            <p className="text-small-larken text-body mt-2">
             Powered by Homesy.
            </p>
          </div>
          <div className="w-7/12 md:w-2/7 relative mx-auto">
            {/* Blurred background image */}
            <img 
              src={property?.image_urls?.length > 0 
                ? property?.image_urls[0] 
                : "/assets/images/placeholder_house.jpg"}
              alt="background blur"
              className="absolute inset-0 top-1.5 h-full w-full scale-95 object-cover blur-[35px] rounded-3xl"
            />
            {/* Main image */}
            <img
              src={
                property?.image_urls?.length > 0
                  ? property?.image_urls[0]
                  : "/assets/images/placeholder_house.jpg"
              }
              alt={property?.propertyid}
              className="w-full aspect-square rounded-3xl object-cover relative"
            />
          </div>
        </div>
        <div className="h-2/3 flex flex-col px-4 w-full gap-10">
           <div className="flex flex-col text-center items-center justify-center gap-2">
              <h1 className="heading-primary md:heading-secondary">
              You’re reserved!
              </h1>
              <p className="heading-quinary p-4 md:px-20">
              Congrats on reserving your Traton built home with Homesy. You’ll have 2 days to schedule a tour and make an offer!
              </p>
           </div>
           <div className="flex flex-col items-center justify-center gap-2">
            <button
                  className="button-text w-full"
                  style={{ backgroundColor: "#601D17" }}
                >
                  Schedule tour
                </button>
                <button 
                  className="button-text-secondary w-full"
                >
                  Manage reservation
                </button>
           </div>
          </div>
      </div>
      <div className="hidden lg:flex flex-row w-full min-h-screen">
        {/* Left Side */}
        <div className="w-full w-3/7 flex flex-col bg-[#F5F4F0] items-center justify-center">
          <div className="w-full flex flex-col gap-8 items-center justify-center h-full">
            <div className="flex flex-col items-center w-full">
              {/* <div className="w-5/7 xl:w-4/7 mx-auto">
                <p className="text-small">
                  Holding your home for {formatTime(timeLeft)} ...
                </p>
              </div> */}
            </div>
            <div className="w-5/7 xl:w-4/7 relative mx-auto">
              {/* Blurred background image */}
              <img 
                src={property?.image_urls?.length > 0 
                  ? property?.image_urls[0] 
                  : "/assets/images/placeholder_house.jpg"}
                alt="background blur"
                className="absolute inset-0 top-1.5 h-full w-full scale-95 object-cover blur-[35px] rounded-3xl"
              />
              {/* Main image */}
              <img
                src={property?.image_urls?.length > 0
                  ? property?.image_urls[0]
                  : "/assets/images/placeholder_house.jpg"}
                alt={property?.propertyid}
                className="w-full aspect-square rounded-3xl object-cover relative"
              />
            </div>
            <div className="flex flex-row items-start w-full w-5/7 xl:w-4/7 mx-auto">
              <div className="w-1/2 flex flex-col gap-2">
                <p className="text-custom">${new Intl.NumberFormat('en-US').format(property?.price)}</p>
                <p className="text-small">
                  {property?.property_full_address || "address not found"}
                </p>
                <a
                  className="text-link"
                  href={property?.property_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#601D17" }}
                >
                  See details
                </a>
              </div>
              <div className="w-1/2 text-custom flex flex-row items-end justify-end gap-4">
                <div className="flex flex-col items-end gap-2">
                  <span className="font-bold">{property?.bedrooms}</span>
                  <span className="text-small">Beds</span>
                </div>
                <div className="flex flex-col items-end gap-2">
                  <span className="font-bold">{property?.bathrooms}</span>
                  <span className="text-small">Baths</span>
                </div>
                <div className="flex flex-col items-end gap-2">
                  <span className="font-bold">{property?.square_feet}</span>
                  <span className="text-small">SqFt</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Right Side - Reservation Details */}
        <div className="w-full lg:w-1/2 flex flex-col items-center gap-10">
          {/* Logo container - shown only on desktop */}
          <div className="h-2/7 flex flex-col items-center text-center justify-center">
            <img
              src="/assets/images/traton_logo.svg"
              alt="brandlogo"
              className="mt-10"
            />
            <p className="text-small-larken text-body mt-2">
              Powered by Homesy.
            </p>
          </div>

          {/* Confirmation Content */}
          <div className="flex flex-col items-center gap-10 w-6/7">
            {currentReservation && property ? (
              <div className="w-full flex flex-col items-center justify-center space-y-4 mb-6 gap-10">
                <h1 className="heading-secondary">
                  Reservation Confirmed!
                </h1>
                <p className="heading-quinary">
                  Congrats on reserving your Traton home with Homesy. Your reservation expires in <span className="text-[#601D17] text-bold">2 days</span>.
                </p>
                <div className="flex flex-col lg:flex-row items-center justify-center gap-2">
                    <button 
                      className="button-text"
                      style={{ backgroundColor: "#601D17" }}
                      onClick={() => navigate('/dashboard')}
                    >
                      Go to dashboard
                    </button>
                    <button 
                      className="button-text-secondary"
                      onClick={() => navigate('/dashboard')}
                    >
                      Manage reservation
                    </button>
                  </div>
              </div>

            ) : (
              <div className="text-center text-customGray font-circular text-[16px] md:text-[18px] lg:text-[20px]">
                No active reservations found.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReserveComplete;