import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { reservePayment } from '../../../api/paymentApi';

function ReservePayment({ builderConfig, closePaymentMethod, propertyData, userInfo, builderInfo, onPaymentSuccess }) {
  const navigate = useNavigate();
  const { propertySlug } = useParams();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    cardNumber: "empty",
    expiration: "empty",
    cvc: "empty",
    nameOnCard: "empty"
  });
  const [showError, setShowError] = useState(false);

  // State to capture form inputs
  const [cardInfo, setCardInfo] = useState({
    cardNumber: "",
    expiration: "",
    cvc: "",
    nameOnCard: ""
  });

  // Handle input change
  const handleCardNumberInputChange = (e) => {
    const { id, value } = e.target;
    let cleanedValue = value.replace(/\D/g, "");
    cleanedValue = cleanedValue.substring(0, 16);
    const formattedValue = cleanedValue.replace(/(\d{4})(?=\d)/g, "$1 ");
    e.target.value = formattedValue;

    if (cleanedValue.length > 12) {
      setErrorMessage({ ...errorMessage, cardNumber: "" });
    } else {
      setErrorMessage({ ...errorMessage, cardNumber: "Invalid card number" });
    }

    setCardInfo((prevState) => ({
      ...prevState,
      [id]: formattedValue
    }));
  };

  const handleExpirationInputChange = (e) => {
    const { id, value } = e.target;
    let cleanedValue = value.replace(/[^\d]/g, "");
    cleanedValue = cleanedValue.substring(0, 6);

    if (cleanedValue.length > 2) {
      cleanedValue = cleanedValue.replace("/", "");
      cleanedValue = `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(2)}`;
    }

    const [month, year] = cleanedValue.split('/');

    if (month && (isNaN(month) || parseInt(month) < 1 || parseInt(month) > 12)) {
      setErrorMessage({ ...errorMessage, expiration: "Invalid month" });
    } else if (year && (isNaN(year) || year.length !== 4 || parseInt(year) < new Date().getFullYear())) {
      setErrorMessage({ ...errorMessage, expiration: "Invalid year" });
    } else if (!month || !year) {
      setErrorMessage({ ...errorMessage, expiration: "Invalid expiration" });
    } else {
      setErrorMessage({ ...errorMessage, expiration: "" });
    }

    e.target.value = cleanedValue;
    setCardInfo((prevState) => ({
      ...prevState,
      [id]: cleanedValue
    }));
  };

  const handleCVVInputChange = (e) => {
    const { id, value } = e.target;
    let cleanedValue = value.replace(/\D/g, "");
    cleanedValue = cleanedValue.substring(0, 4);

    if (cleanedValue.length < 3) {
      setErrorMessage({ ...errorMessage, cvc: "Invalid CVV" });
    } else {
      setErrorMessage({ ...errorMessage, cvc: "" });
    }

    e.target.value = cleanedValue;
    setCardInfo((prevState) => ({
      ...prevState,
      [id]: cleanedValue
    }));
  };

  const handleNameInputChange = (e) => {
    const { id, value } = e.target;
    let cleanedValue = value.replace(/[^a-zA-Z\s'-]/g, "");
    cleanedValue = cleanedValue.substring(0, 50);
    const nameParts = cleanedValue.trim().split(/\s+/);

    if (nameParts.length < 2) {
      setErrorMessage({ ...errorMessage, nameOnCard: "Please enter full name (First and Last name)" });
    } else {
      setErrorMessage({ ...errorMessage, nameOnCard: "" });
    }

    e.target.value = cleanedValue;
    setCardInfo((prevState) => ({
      ...prevState,
      [id]: cleanedValue
    }));
  };

  // Handle payment submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (errorMessage.cardNumber || errorMessage.expiration || errorMessage.cvc || errorMessage.nameOnCard) {
      setShowError(true);
      return;
    }

    setShowError(false);
    setLoading(true);

    try {
      // const response = await reservePayment({
      //   merchantId: builderInfo?.merchantid,
      //   propertyId: propertyData?.propertyid,
      //   amount: "100",
      //   userInfo: userInfo,
      //   cardInfo: {
      //     ...cardInfo,
      //     cardNumber: cardInfo.cardNumber.replace(/\s/g, ''),
      //   }
      // });
      const response = {
        data: {
          id: "mock_payment_id",
          status: "success", 
          amount: "100",
          timestamp: new Date().toISOString()
        }
      };
      await onPaymentSuccess(response.data);
      navigate(`/${propertySlug}/reserve/complete`);
      
      // if (response?.status === "success") {
      //   await onPaymentSuccess(response.data);
      //   navigate(`/${propertySlug}/reserve/complete`);
      // } else {
      //   throw new Error(response?.error || 'Payment failed');
      // }
    } catch (error) {
      console.error('Payment error:', error);
      setShowError(true);
      setErrorMessage(prev => ({
        ...prev,
        submit: error?.response?.data?.error || error.message || 'Payment failed. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  const buttonStyle = {
    backgroundColor: builderConfig?.color
  };

  return (
    <div className="full-screen dark-overlay">
      <div className="payment-method p-6 bg-white shadow-md max-w-lg mx-auto gap-5">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={closePaymentMethod}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Logo and Title */}
        <img
          src="/assets/images/traton_logo.svg"
          alt="homesylogo"
          className="w-[175px] h-auto mx-auto"
        />
        <p className="text-small-larken">
          Powered by Homesy.
        </p>
        <h1 className="heading-tertiary">
          Reserve your home.
        </h1>

        {/* Payment Form */}
        <form className="space-y-4 flex flex-grow flex-col" onSubmit={handleSubmit}>
          <div className="flex flex-col flex-grow space-y-4">
            <input
              type="text"
              id="cardNumber"
              className="w-full p-2 border rounded-md"
              placeholder="Card number"
              onChange={handleCardNumberInputChange}
            />

            <div className="flex space-x-4">
              <input
                type="text"
                id="expiration"
                className="w-1/2 p-2 border rounded-md"
                placeholder="Expiration (MM/YYYY)"
                onChange={handleExpirationInputChange}
              />
              <input
                type="text"
                id="cvc"
                className="w-1/2 p-2 border rounded-md"
                placeholder="CVC"
                onChange={handleCVVInputChange}
              />
            </div>

            <input
              type="text"
              id="nameOnCard"
              className="w-full p-2 border rounded-md"
              placeholder="Name on card"
              onChange={handleNameInputChange}
            />
          </div>

          {/* Error Messages */}
          {showError && (
            <div className="text-red-500 text-sm text-center">
              {errorMessage.cardNumber && <p>{errorMessage.cardNumber}</p>}
              {errorMessage.expiration && <p>{errorMessage.expiration}</p>}
              {errorMessage.cvc && <p>{errorMessage.cvc}</p>}
              {errorMessage.nameOnCard && <p>{errorMessage.nameOnCard}</p>}
              {errorMessage.submit && <p>{errorMessage.submit}</p>}
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            style={buttonStyle}
            className="button-text"
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processing...
              </div>
            ) : (
              "Reserve now for $100"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ReservePayment;
