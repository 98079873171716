import React from 'react';
import ProgressBar from '../../../ProgressBar';

const NameStep = ({ userInfo, onChange, errors, onNext, onKeyDown }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
    if (errors[name]) {
      onChange('errors', {
        ...errors,
        [name]: ''
      });
    }
  };

  const handleSubmit = () => {
    if (!userInfo.firstName?.trim() || !userInfo.lastName?.trim()) {
      onChange('errors', {
        ...errors,
        firstName: !userInfo.firstName?.trim() ? 'First name is required' : '',
        lastName: !userInfo.lastName?.trim() ? 'Last name is required' : ''
      });
      return false;
    }
    onNext();
    return true;
  };

  return (
    <>
      <ProgressBar currentStep={3} totalSteps={7} />
      <div className="mb-6 flex flex-col items-center gap-10">
        <h1 className="heading-secondary xl:heading-secondary">
          What's your name?
        </h1>
        <p className="heading-quinary xl:heading-tertiary">
          Please use your full legal name.
        </p>
      </div>

      <div className="w-full flex flex-col gap-4">
        <div>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={userInfo.firstName || ''}
            onChange={handleChange}
            onBlur={handleSubmit}
            onKeyDown={onKeyDown}
            className="w-full px-4 py-3 text-base border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
            />
          {errors.firstName && (
          <p className="text-red-500 text-sm text-left mt-4">
              {errors.firstName}
            </p>
          )}
        </div>

        <div>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={userInfo.lastName || ''}
            onChange={handleChange}
            className="w-full px-4 py-3 text-base border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
            onBlur={handleSubmit}
            onKeyDown={onKeyDown}
          />
          {errors.lastName && (
            <p className="text-red-500 text-sm text-left">
              {errors.lastName}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default NameStep; 