import { configureStore } from '@reduxjs/toolkit';
import propertyReducer from './slices/propertySlice';
import flowReducer from './slices/flowSlice';
import builderReducer from './slices/builderSlice';
import reservationFormReducer from './slices/reservationFormSlice';
import userReducer from './slices/userSlice';
import reservationReducer from './slices/reservationSlice';
import builderAgentsReducer from './slices/builderAgentsSlice';
export const store = configureStore({
    reducer: {
        property: propertyReducer,
        flow: flowReducer,
        builder: builderReducer,
        reservationForm: reservationFormReducer,
        user: userReducer,
        builderAgents: builderAgentsReducer,
        reservations: reservationReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
});